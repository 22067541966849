
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
import { Detail } from '@/types/healthLife'

@Component({
  name: 'HealthLifeDetail',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  private info: Detail | {} = {}

  get lifeId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getDetail()
  }

  // 获取详情
  getDetail () {
    this.$axios.get(this.$apis.healthLife.selectHealthLifeByLifeId, {
      lifeId: this.lifeId
    }).then(res => {
      this.info = res || {}
    })
  }
}
